import React, { Component } from "react";
import { useEffect } from "react";

export function HeroSection() {
  // useEffect(() => {
  //   const hoverable = document.querySelectorAll("p");
  //   for (let i = 0; i < hoverable.length; i++) {
  //     hoverable[i].style.transition = '2s ease-in';
      

  //     hoverable[i].addEventListener("mouseenter", () => {
  //       hoverable[i].style.webkitTextFillColor = "rgba(67, 67, 67, 1.0)";
  
  //       setTimeout(() => {
  //         hoverable[i].style.webkitTextFillColor = "";
  //       }, 15000);
  //     });
  //   }

  //   const barLeft = document.querySelector(".left-bar");
  //   const barRight = document.querySelector(".right-bar");
  //   barLeft.addEventListener("mouseenter", (e) => {
  //     e.target.style.backgroundColor = "rgba(42, 180, 180, 0.9)";
  //     setTimeout(() => {
  //       e.target.style.backgroundColor = "";
  //     }, 15000);
  //   });
  //   barRight.addEventListener("mouseenter", (e) => {
  //     e.target.style.backgroundColor = "rgba(42, 180, 180, 0.9)";
  //     setTimeout(() => {
  //       e.target.style.backgroundColor = "";
  //     }, 15000);
  //   });
  // });
  return (
    
      <div className="container">
        <div className="container-left">
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>A</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>A</p>
            </div>
            <div className="hover letter">
              <p>C</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>A</p>
            </div>
            <div className="hover letter">
              <p>C</p>
            </div>
            <div className="hover letter">
              <p>T</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>A</p>
            </div>
            <div className="hover letter">
              <p>C</p>
            </div>
            <div className="hover letter">
              <p>T</p>
            </div>
            <div className="hover letter">
              <p>I</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>A</p>
            </div>
            <div className="hover letter">
              <p>C</p>
            </div>
            <div className="hover letter">
              <p>T</p>
            </div>
            <div className="hover letter">
              <p>I</p>
            </div>
            <div className="hover letter">
              <p>V</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>A</p>
            </div>
            <div className="hover letter">
              <p>C</p>
            </div>
            <div className="hover letter">
              <p>T</p>
            </div>
            <div className="hover letter">
              <p>I</p>
            </div>
            <div className="hover letter">
              <p>V</p>
            </div>
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>A</p>
            </div>
            <div className="hover letter">
              <p>C</p>
            </div>
            <div className="hover letter">
              <p>T</p>
            </div>
            <div className="hover letter">
              <p>I</p>
            </div>
            <div className="hover letter">
              <p>V</p>
            </div>
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>A</p>
            </div>
            <div className="hover letter">
              <p>C</p>
            </div>
            <div className="hover letter">
              <p>T</p>
            </div>
            <div className="hover letter">
              <p>I</p>
            </div>
            <div className="hover letter">
              <p>V</p>
            </div>
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
            <div className="hover letter">
              <p>O</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>A</p>
            </div>
            <div className="hover letter">
              <p>C</p>
            </div>
            <div className="hover letter">
              <p>T</p>
            </div>
            <div className="hover letter">
              <p>I</p>
            </div>
            <div className="hover letter">
              <p>V</p>
            </div>
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
            <div className="hover letter">
              <p>O</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>A</p>
            </div>
            <div className="hover letter">
              <p>C</p>
            </div>
            <div className="hover letter">
              <p>T</p>
            </div>
            <div className="hover letter">
              <p>I</p>
            </div>
            <div className="hover letter">
              <p>V</p>
            </div>
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
            <div className="hover letter">
              <p>O</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
          </div>
        </div>

        <div className="container-mid">
          <div className="left-bar bar"></div>
          <div className="letters">
            <div className="hover letter">
              <p>A</p>
            </div>
            <div className="hover letter">
              <p>C</p>
            </div>
            <div className="hover letter">
              <p>T</p>
            </div>
            <div className="hover letter">
              <p>I</p>
            </div>
            <div className="hover letter">
              <p>V</p>
            </div>
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover letter">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
            <div className="hover letter">
              <p>O</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>Y</p>
            </div>
          </div>
          <div className="right-bar bar"></div>
        </div>

        <div className="container-right">
          <div className="line">
            <div className="hover letter">
              <p>C</p>
            </div>
            <div className="hover letter">
              <p>T</p>
            </div>
            <div className="hover letter">
              <p>I</p>
            </div>
            <div className="hover letter">
              <p>V</p>
            </div>
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
            <div className="hover letter">
              <p>O</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>Y</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover letter">
              <p>T</p>
            </div>
            <div className="hover letter">
              <p>I</p>
            </div>
            <div className="hover letter">
              <p>V</p>
            </div>
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
            <div className="hover letter">
              <p>O</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>Y</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover letter">
              <p>I</p>
            </div>
            <div className="hover letter">
              <p>V</p>
            </div>
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover letter">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
            <div className="hover letter">
              <p>O</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>Y</p>
            </div>
            <div className="hover letter">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover letter">
              <p>V</p>
            </div>
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
            <div className="hover letter">
              <p>O</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>Y</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover letter">
              <p>E</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
            <div className="hover letter">
              <p>O</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>Y</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover letter">
              <p>H</p>
            </div>
            <div className="hover letter">
              <p>O</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>Y</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover letter">
              <p>O</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>Y</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>Y</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover letter">
              <p>B</p>
            </div>
            <div className="hover letter">
              <p>Y</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover letter">
              <p>Y</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="line">
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
            <div className="hover space">
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    
  );
}
