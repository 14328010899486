import React from "react";
import NavBar from "./NavBar";
function Header() {
  return (
    <div className="header">
      <NavBar></NavBar>
      {/* <img src="images/title.jpeg" alt="Avatar" className="title-image"></img> */}
    </div>
  );
}

export default Header;
