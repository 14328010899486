import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { HeroSection } from "./components/HeroSection";
import SpotifyWidget from "./components/SpotifyWidget";

import ProductSlider from "./components/ProductSlider";
import Contact from "./components/Contact";

import { Route, Routes, Link, Outlet } from "react-router-dom";

export default function App() {
  // const [nowPlaying, setShow] = useState(true);
  // const handleToggle = useCallback(() => setShow(prevShow => !prevShow),[])

  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HeroSection />} />
          <Route path="artists" element={<HeroSection />} />
          <Route path="releases" element={<HeroSection />} />
          <Route path="contact" element={<HeroSection />} />
          <Route path="shop" element={<HeroSection />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div className="layout">
      
        <Header></Header>
      
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <div className="content">
        <Outlet />
      </div>

      <div className="footer">
      {/* <div className="spotify-widget">
      <SpotifyWidget
        link={
          "https://open.spotify.com/embed/album/6HvygDBnwEXIzjgPQjfUHF?utm_source=generator"
        }
      />
      </div> */}
        <Footer></Footer>
      </div>
    </div>
  );
}

function Home() {
  return (
    <div>
      <h2></h2>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Artists() {
  return (
    <div>
      <div className="slider">
        <ProductSlider></ProductSlider>
      </div>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
