import Nav from "react-bootstrap/Nav";
import { Route, Routes, Link, Outlet } from "react-router-dom";
import styled from "styled-components";

const NavUnlisted = styled.ul`
  text-decoration: none;
`;
const linkStyle = {
  margin: "8px",
  textDecoration: "none",
};

function NavBar() {
  return (
    <>
    <h1>A /// H</h1>

      <nav className="navigation">
        <ul>
          <li>
            <Link to="/artists" style={linkStyle}>
              Artists
            </Link>
          </li>
          <li>
            <Link to="/releases" style={linkStyle}>
              {" "}
              Releases
            </Link>
          </li>
          <li>
            <Link to="/contact" style={linkStyle}>
              Contact
            </Link>
          </li>
          <li>
            <Link to="/shop" style={linkStyle}>
              Shop
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default NavBar;
