import React, { Component } from "react";

export default class ContentComponent extends React.Component {
  content = () => {
    return <div>Displaying text</div>;
  };

  render() {
    return <div>{this.content()}</div>;
  }
}
