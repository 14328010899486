import React from "react";
import ContentComponent from "./Content";

class SpotifyWidget extends React.Component {
  state = {
    link: "",
  };
  render() {
    // const { link } = this.state.link;

    return (
      <div className="spotify-widget">
        <h4></h4>
        <iframe
          title="now-playing"
          src={this.props.link}
          width="390vw"
          
          frameBorder=""
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    );
  }
}

export default SpotifyWidget;
